import React from 'react'
import Layout from '../containers/layout'
import SEO from '../components/seo'

const NotFoundPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <section>
        <h1>404: </h1>
        <p>
          <strong>هذه الصفحة غير متاحة </strong>
        </p>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const Head = ({ location }) => <SEO pathname={location.pathname}></SEO>